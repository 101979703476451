import type { NextPage } from "next";
import { useUser } from "@auth0/nextjs-auth0";
import { AdvisorRoutes, ClientRoutes } from "@common/constants";
import LoadingIndicator from "@common/components/LoadingIndicator";
import { useEffect } from "react";
import { useRouter } from "@common/hooks";
import { resetPersistedStores } from "@common/utils";

const Index = () => {
  // NOTE: if you land here and you don't have a session (new user)
  // we will show a loading indicator while analytics (heap) scripts
  // load and other components inside <DefaultLayout/>.
  // Once <AdvisorChat/> loads, it will call withPageAuthRequired
  // which triggers a redirect by auth0-nextjs to the login page.
  const { user } = useUser();
  const router = useRouter();
  useEffect(() => {
    async function handleRedirect() {
      if (user) {
        if (user["https://formefinancial.com/advisor"]) {
          await resetPersistedStores();
          router.push({
            pathname: AdvisorRoutes.Households,
            query: router.query,
          });
        } else {
          await resetPersistedStores();
          router.push({ pathname: ClientRoutes.Overview, query: router.query });
        }
      }
    }
    handleRedirect();
  }, [user, router]);
  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 bg-white">
      <LoadingIndicator isVisible={true} />
    </div>
  );
};

export default Index as NextPage;
